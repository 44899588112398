/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import './src/global.css'

const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

export const onRouteUpdate = () => {
  addScript(`//scripts.iconnode.com/${process.env.GATSBY_WHATCONVERTS_NUMBER}.js`)
}

export { wrapPageElement } from './gatsby-shared'
